import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/components/Layout.vue'
import LayoutPlay from '@/components/LayoutPlay.vue'
import LayoutDetail from '@/components/LayoutDetail.vue'
import LayoutTitle from '@/components/LayoutTitle.vue'
import store from '@/store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'home',
    component: Layout,
    meta: {
      requiresAuth: true,
      page: 4,
    },
    children:[
      {
        path: '/home',
        name: 'home',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
        meta: {
          page: 4,
        },
      },
    ]
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import(/* webpackChunkName: "login" */ '../views/Faq.vue'),
    meta: {
      page: 22,
    }
  },
  {
    path: '/tnc',
    name: 'tnc',
    component: () => import(/* webpackChunkName: "login" */ '../views/Syarat.vue'),
    meta: {
      page: 23,
    }
  },
  {
    path: '/comp-tnc-faq',
    name: 'comp_tnc_faq',
    component: () => import(/* webpackChunkName: "login" */ '../views/FaqTnc.vue'),
    meta: {
      page: 23,
    }
  },
  {
    path: '/play',
    name: 'play',
    component: LayoutPlay,
    meta: {
      requiresAuth: true,
      page: 6,
    }
  },
  {
    path: '',
    component: LayoutTitle,
    meta: {
      requiresAuth: true,
      page: 5,
    },
    children:[
      {
        path: '/game/:id',
        name: 'game',
        component: () => import(/* webpackChunkName: "game" */ '../views/Game.vue'),
        meta: {
          page: 7,
        }
      },
      {
        path: '/leaderboard',
        name: 'leaderboard',
        component: () => import(/* webpackChunkName: "leaderboard" */ '../views/Leaderboard.vue'),
        meta: {
          page: 8,
        }
      },
      {
        path: '/reward',
        name: 'reward',
        component: () => import(/* webpackChunkName: "leaderboard" */ '../views/Reward.vue'),
        meta: {
          page: 8,
        }
      },
      // {
      //   path: '/redeem',
      //   name: 'redeem',
      //   component: () => import(/* webpackChunkName: "redeem" */ '../views/Redeem.vue'),
      //   meta: {
      //     page: 9,
      //   }
      // },
      {
        path: '/store',
        name: 'store',
        component: () => import(/* webpackChunkName: "store" */ '../views/Store.vue'),
        meta: {
          page: 9,
        }
      },
      {
        path: '/howto',
        name: 'howto',
        component: () => import(/* webpackChunkName: "tnc" */ '../views/Howtoplay.vue'),
        meta: {
          page: 9,
        }
      },
      {
        path: '/inbox',
        name: 'inbox',
        component: () => import(/* webpackChunkName: "tnc" */ '../views/Inbox.vue'),
        meta: {
          page: 9,
        }
      },
    ]
  },
  {
    path: '',
    component: LayoutDetail,
    meta: {
      requiresAuth: true,
      page: 10,
    },
    children:[
      {
        path: '/redeem/:id',
        name: 'redeemDetail',
        component: () => import(/* webpackChunkName: "about" */ '../components/DetailRedeem.vue'),
        meta: {
          page: 10,
        }
      },
      {
        path: '/inbox/:id',
        name: 'inboxDetail',
        component: () => import(/* webpackChunkName: "about" */ '../components/DetailInbox.vue'),
        meta: {
          page: 10,
        }
      },
      {
        path: '/reward/:id',
        name: 'rewardDetail',
        component: () => import(/* webpackChunkName: "about" */ '../components/DetailReward.vue'),
        meta: {
          page: 10,
        }
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      page: 1,
    }
  },
  {
    path: '/otp',
    name: 'otp',
    component: () => import(/* webpackChunkName: "login" */ '../views/Otp.vue'),
    meta: {
      page: 2,
    }
  },
  {
    path: '/loading',
    name: 'loading',
    component: () => import(/* webpackChunkName: "login" */ '../views/Loadingscreen.vue'),
    meta: {
      requiresAuth: true,
      page: 3,
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior (to, from, savedPosition) {
    // console.log(to, from, savedPosition)
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isUser || store.state.user) {
      next()
      return
    }
    next({path:'/login'})
  } else {
    next()
  }
})

export default router
