<template>
  <div class="content-login h-100">
    <b-container fluid class="h-100 px-0">
      <div id="navi">
        <!-- <b-button v-b-toggle.sidebar-backdrop class="fixed-top">Toggle Sidebar</b-button> -->
        <div class="fixed-top text-left ml-3 mt-3 mx-2 my-2">
          <b-avatar
          button
            :src="require('@/assets/icon-back.png')"
            size="2.5rem"
            @click="goBack()"
            variant="bg-transparent"
          ></b-avatar>
        </div>
      </div>
      <transition :name="transitionName">
        <router-view />
      </transition>
      <!-- <div class="footer"> -->
        <!-- <span>Powered by </span>
        <b-img
          :src="require('@/assets/love.png')"
          alt="Media Aside"
          style="width:2rem;"
        ></b-img> -->
      <!-- </div> -->
    </b-container>
  </div>
</template>
<script>
// import Nav from "@/components/Nav.vue";
export default {
  components: {
    // Nav
  },
  data() {
    return {
      transitionName: ""
    }
  },
    // watch: {
    //     $route(to, from) {
    //     this.transitionName = to.meta.page > from.meta.page ? "next" : "prev";
    //     }
    // },
  methods: {
    goBack() {
      this.$router.go(-1)
      // console.log('cliked')
    },
    goTo(page) {
      this.$router.push({ name: page });
    },
  }
};
</script>
<style scoped>
#navi {
  z-index: 1000;
}
.content-login {
  /* background-color: rgb(241, 239, 239); */
  background-image: url("../assets/bg-portal.png");
  background-position: center;
  background-size: contain;
  min-height: 100vh;
}
.footer {
  position: absolute;
  width: 100%;
  padding: 13px;
  /* background: chartreuse; */
  left: 0px;
}
</style>
