<template>
  <div>
    <b-modal
      :id="ids"
      hide-header
      hide-footer
      centered
      no-close-on-backdrop
      dialog-class="wd-custom m-auto"
    >
      <div class="d-block text-center r-dx">
        <b-img :src="dataPopup.image" center fluid rounded></b-img>
        <h3 class="title-popup">{{ dataPopup.title }}</h3>
        <p class="txt-desc">{{ dataPopup.description }}</p>
      </div>
      <b-button
        variant="warning"
        class="mt-3"
        block
        @click="tambahToken()"
        >{{ dataPopup.txtButton }}</b-button
      >
    </b-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      
    };
  },
  props: {
      dataPopup: Object,
      ids: String,
    },
  methods: {
      showPopup(id) {
          this.$bvModal.show(id);
      },
      hidePopup(id) {
          this.$bvModal.hide(id);
      },
      tambahToken() {
        //   this.$store.state.coin = 1
          this.hidePopup(this.ids)
          // this.$emit('addCoin')
        // this.renderComponent = true;
          // console.log('clicked', this.$store.state.coin);
          if (this.ids === 'popupToken') {
            location.replace('/store')
          }
      }
    // showPopupHadiah(point) {
    //   const index = this.reward.findIndex(
    //     item => item.point === point
    //   );
    //   const reward = this.reward[index];
    //   this.dataPopup.image = reward.image;
    //   this.dataPopup.title = reward.title;
    //   this.dataPopup.description = reward.description;
    //   this.dataPopup.txtButton = "Ambil";
    //   this.$bvModal.show("popupModal");
    // },
    // showPopupValidateToken() {
    //   const token = this.token;
    //   this.dataPopup.image = token.image;
    //   this.dataPopup.title = token.title;
    //   this.dataPopup.description = token.description;
    //   this.dataPopup.txtButton = "Tambah Token";
    //   console.log('muncul', this.point);
    //   this.$bvModal.show("popupModal");
    //   // this.$refs['popupModal'].show()
    // },
    // setPopup() {
    //     if (this.totalPoint !== null) {
    //       if (this.totalPoint === '0') {
    //           this.showPopupValidateToken()
    //       } else {
    //           this.showPopupHadiah(this.totalPoint)
    //       }
    //   } else {
    //       this.$bvModal.hide("popupModal")
    //   }
      
    // },
  }
};
</script>
<style scoped>
/deep/ .wd-custom {
    width: 45% !important;
}
/deep/ .title-popup {
    font-size: 1.1rem;
}
/deep/ .txt-desc {
    font-size: .8rem;
}
</style>
